import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Avatar from '../components/Avatar';
import SEO from '../components/SEO';
import styled from '@emotion/react';
import { css } from '@emotion/react';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <SEO title="Curriculum Vitae" mdxType="SEO" />
    <article>
      <h1>Curriculum Vitae</h1>
      <Avatar css={css`
    float: left;
    margin: 0 15px 15px 0;
  `} mdxType="Avatar" />
      <p>{`J'ai 26 ans et je suis passionné d'informatique au sens large : développement, technologies web, hardware, Linux, IoT, ...`}</p>
      <p>{`Les sujets qui me passionnent actuellement sont la conception d'interfaces respectant les problématiques d'accessibilité, l'optimisation des rendus côté serveur (SSR) ainsi que la création de systèmes de design.`}</p>
      <p>{`Je travaille actuellement à `}<a parentName="p" {...{
          "href": "https://yousign.com/"
        }}>{`Yousign`}</a>{`, un éditeur de logiciel français qui commercialise un `}<abbr title="Software as a service">{`SaaS`}</abbr>{` de signature électronique, où je suis développeur front-end.`}</p>
      <h2>{`Expériences professionnelles`}</h2>
      <h3><a parentName="h3" {...{
          "href": "https://yousign.com/"
        }}>{`Yousign`}</a>{` — Développeur front-end : 2022 – ...`}</h3>
      <ul>
        <li parentName="ul">{`Développement de nouvelles fonctionnalités`}</li>
        <li parentName="ul">{`Participation à l'amélioration de l'accessibilité pour les utilisateurs externes (processus de signature)`}</li>
      </ul>
      <h3><a parentName="h3" {...{
          "href": "https://knplabs.com/fr"
        }}>{`KNP Labs`}</a>{` — Développeur web : 2021 – 2022`}</h3>
      <p>{`Mission pour la `}<a parentName="p" {...{
          "href": "https://www.maif.fr/"
        }}>{`MAIF`}</a>{` :`}</p>
      <ul>
        <li parentName="ul">{`Migration d'un progiciel développé en `}<a parentName="li" {...{
            "href": "https://angular.io/"
          }}>{`Angular`}</a>{` vers `}<a parentName="li" {...{
            "href": "https://reactjs.org/"
          }}>{`React`}</a>{` avec refonte du design`}</li>
        <li parentName="ul">{`Développement de composants respectant les bonnes pratiques du `}<a parentName="li" {...{
            "href": "https://www.w3.org/TR/WCAG21/"
          }}>{`WCAG`}</a></li>
        <li parentName="ul">{`Estimation et découpage des différentes tâches`}</li>
        <li parentName="ul">{`Accompagnement et formation de junior`}</li>
      </ul>
      <p>{`Interventions sur d'autres projets clients pour des revues codes.`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://pepperbay.fr/"
        }}>{`Pepperbay`}</a>{` — Développeur web : 2019 – 2021`}</h3>
      <p>{`En charge de la refonte de l'interface d'une application de négoce international.`}<br parentName="p"></br>{`
`}{`Choix des technologies (frameworks & bibliothèques).`}</p>
      <p>{`Stack technique :`}</p>
      <ul>
        <li parentName="ul">{`Bibliothèque d'interface : `}<a parentName="li" {...{
            "href": "https://reactjs.org/"
          }}>{`React`}</a></li>
        <li parentName="ul">{`Gestion du state global : `}<a parentName="li" {...{
            "href": "https://redux.js.org/"
          }}>{`Redux`}</a></li>
        <li parentName="ul">{`Gestion des effets : `}<a parentName="li" {...{
            "href": "https://redux-saga.js.org/"
          }}>{`Redux-Saga`}</a></li>
        <li parentName="ul">{`Gestion des composants graphiques : `}<a parentName="li" {...{
            "href": "https://storybook.js.org/"
          }}>{`Storybook`}</a></li>
        <li parentName="ul">{`Gestion de l'internationalisation : `}<del parentName="li"><a parentName="del" {...{
              "href": "https://www.i18next.com/"
            }}>{`i18next`}</a></del>{` Solution custom plus légère`}</li>
        <li parentName="ul">{`Divers : `}<a parentName="li" {...{
            "href": "https://webpack.js.org/"
          }}>{`Webpack`}</a>{`, `}<a parentName="li" {...{
            "href": "https://ramdajs.com/"
          }}>{`Ramda`}</a>{`, `}<a parentName="li" {...{
            "href": "https://github.com/typicode/husky"
          }}>{`Husky`}</a>{`, `}<a parentName="li" {...{
            "href": "https://eslint.org/"
          }}>{`ESLint`}</a>{`, `}<a parentName="li" {...{
            "href": "https://prettier.io/"
          }}>{`Prettier`}</a></li>
      </ul>
      <h3><a parentName="h3" {...{
          "href": "https://pepperbay.fr/"
        }}>{`Pepperbay`}</a>{` — Contrat de professionnalisation : 2018 – 2019`}</h3>
      <p>{`Développement d'un logiciel de négoce international, une application monolithique en Symfony 3.4 / Twig.
L'enjeu du projet est sa refonte complète, mais sans interruption dans le développement de nouvelles fonctionnalités :`}</p>
      <ul>
        <li parentName="ul">{`Back : API Platform (Symfony 4)`}</li>
        <li parentName="ul">{`Front : ReactJS`}</li>
      </ul>
      <h2>{`Compétences`}</h2>
      <ul>
        <li parentName="ul">{`Langages : Javascript, Python, PHP, ...`}</li>
        <li parentName="ul">{`CI : Gitlab CI, GitHub Actions, Jenkins, ...`}</li>
        <li parentName="ul">{`Système : NGINX, Docker, Makefile, Kubernetes, Linux, ...`}</li>
        <li parentName="ul">{`Méthodologie : SCRUM`}</li>
      </ul>
      <h2>{`Parcours scolaire`}</h2>
      <h3><a parentName="h3" {...{
          "href": "http://www.epsi.fr/"
        }}>{`EPSI`}</a>{` — Ingénierie Informatique : 2017 – 2019`}</h3>
      <p>{`Formation sanctionnée par un titre RNCP de niveau I (Bac+5) « Expert en Informatique et Système d'Information », et réalisée en alternance dans l'entreprise `}<a parentName="p" {...{
          "href": "https://www.fleurymichon.fr/"
        }}>{`Fleury Michon`}</a>{` et `}<a parentName="p" {...{
          "href": "https://www.pepperbay.fr/"
        }}>{`Pepperbay`}</a>{`.`}</p>
      <h3><a parentName="h3" {...{
          "href": "http://iia-laval.fr/"
        }}>{`IIA Laval`}</a>{` — Licence Informatique Générale : 2016 – 2017`}</h3>
      <p>{`Formation en partenariat avec le CNAM, réalisée en contrat de professionnalisation dans l'entreprise `}<a parentName="p" {...{
          "href": "https://www.fleurymichon.fr/"
        }}>{`Fleury Michon`}</a>{`.`}</p>
      <h3><a parentName="h3" {...{
          "href": "https://iutnantes.univ-nantes.fr/formations/dut-bac-2-/dut-informatique-2019469.kjsp"
        }}>{`IUT de Nantes`}</a>{` — DUT Informatique : 2014 – 2016`}</h3>
      <p>{`Parcours des bases de l'informatique (conception, pilotage de projet, web, base de données, ...).`}</p>
      <h2>{`Associatif`}</h2>
      <h3>{`Association Art'Culmine — Régisseur : 2015 – ...`}</h3>
      <p>{`Conception de scène et mise en lumière.`}<br parentName="p"></br>{`
`}{`Conception technique son, vidéo et lumière.`}</p>
      <h3>{`BDE Astro'Nantes — Vice-Président : 2015 – 2016`}</h3>
      <p>{`Organisation d'événements pour une association étudiante.`}</p>
      <h3>{`Cinéscénie du Puy du Fou — Acteur et cavalier : 2007 – 2021`}</h3>
      <p>{`Participation à un grand spectacle vivant racontant l'histoire de la région.`}</p>
      <h3>{`Association Asia Project — Vidéo Jockey : 2013`}</h3>
      <p>{`Conception et mix vidéo en direct pour illustrer les mixs de DJ.`}</p>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      